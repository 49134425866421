import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Seo from '../components/seo';
import Layout from '../components/layout';
import { getLowerCaseUrlParam, setOmCode, setPhoneLocalStorage } from '../components/utilities';

class AggregatorTemplate extends React.Component {
     componentDidMount() {
          const aggregator = get(this.props, 'data.contentfulAggregatorPages');
          let href = get(this.props, 'location.href');
          const hardcodedParams = 'ENROLL=N';
          let urlParams = '';

          if (typeof window != 'undefined') {
               const petName = getLowerCaseUrlParam(href)['petname'];
               const zipCode = getLowerCaseUrlParam(href)['zipcode'];
               const speciesCode = getLowerCaseUrlParam(href)['speciescode'] || getLowerCaseUrlParam(href)['species'];

               const om = getLowerCaseUrlParam(href)['om'] || aggregator.trackingCode || localStorage.getItem('Petinsurance_OM');
               setOmCode(om, href);

               const petAge = getLowerCaseUrlParam(href)['petage'] || getLowerCaseUrlParam(href)['age'];
               const breedType = getLowerCaseUrlParam(href)['breedtype'];
               const breedCode = getLowerCaseUrlParam(href)['breedcode'];
               const email = getLowerCaseUrlParam(href)['email'];
               const firstName = getLowerCaseUrlParam(href)['firstname'];
               const lastName = getLowerCaseUrlParam(href)['lastname'];

               const phone = getLowerCaseUrlParam(href)['phone'] || aggregator.campaignPhone || localStorage.getItem('Petinsurance_TrackingPhone');
               setPhoneLocalStorage(phone, href);

               urlParams = petName ? `${urlParams}&petName=${petName}` : urlParams;
               urlParams = zipCode ? `${urlParams}&zipCode=${zipCode}` : urlParams;
               urlParams = speciesCode ? `${urlParams}&speciesCode=${speciesCode}` : urlParams;
               urlParams = om ? `${urlParams}&OM=${om}` : urlParams;
               urlParams = petAge ? `${urlParams}&petAge=${petAge}` : urlParams;
               urlParams = breedType ? `${urlParams}&breedType=${breedType}` : urlParams;
               urlParams = breedCode ? `${urlParams}&breedCode=${breedCode}` : urlParams;
               urlParams = email ? `${urlParams}&email=${email}` : urlParams;
               urlParams = firstName ? `${urlParams}&firstName=${firstName}` : urlParams;
               urlParams = lastName ? `${urlParams}&lastName=${lastName}` : urlParams;
               urlParams = phone ? `${urlParams}&phone=${phone}` : urlParams;

               window.location.replace(`https://${process.env.GATSBY_QECURLBYENVIRONMENT}/?${hardcodedParams}${urlParams}`);
          }
     }

     render() {
          const page = get(this.props, 'data.contentfulAggregatorPages')
          
          return (
               <Layout>
                    <Seo showAntiFlickerSnippet={page.showAntiFlickerSnippet}/>
               </Layout>
          )
     }
}

export default AggregatorTemplate;

export const pageQuery = graphql`
     query AggregatorPageBySlug($slug: String!) {
          site {
               siteMetadata {
                    title
               }
          }
          contentfulAggregatorPages(slug: {eq: $slug }) {
               ...ContentfulAggregatorPagesFields
          }
     }
`
